import { Title } from '@solidjs/meta';

export default function ComponentsPage() {
	return (
		<>
			<Title>Components | Styleguide | Troon</Title>

			<h1 class="mb-8 text-4xl font-semibold">Components</h1>

			<ul class="mb-8 grid grid-cols-12 gap-4" />
		</>
	);
}
